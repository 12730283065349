import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import InvoiceItem from '../components/InvoiceItem'
import InvoiceElectronic from '../assets/icons/invoice_electronic.svg'
import InvoiceZugfred from '../assets/icons/invoice_zugferd.svg'
import InvoiceX from "../assets/icons/invoice_x.svg";
import { useHistory } from 'react-router-dom'
import { updateUserDetails } from '../api/helpers'
import { AxiosResponse } from 'axios'
import Loading from '../components/Loading'


export const InvoiceChannelItems = {
  'E_INVOICES': {
    'title': 'e-invoices',
    iconSrc: InvoiceElectronic,
  },
  'ZUGFERD': {
    'title': 'zugferd',
    iconSrc: InvoiceZugfred,
  },
  'X_INVOICES': {
    'title': 'x-invoices',
    iconSrc: InvoiceX,
  }
}

const InvoiceChannel = () => {
  const { formatMessage } = useIntl();

  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState<string | undefined>(undefined)

  const handleSubmit = async () => {
    setShowLoading(true)
    const data = {
      terms_accepted: true,
      invoice_type: selectedChannel
    }
    updateUserDetails(data)
      .then(function (response: AxiosResponse<any>) {
        setShowLoading(false)
        history.push('/dashboard')
      })
      .catch(function (error) {
        setShowLoading(false)
      });
  }

  return (
    <div className="page-container">
      <Loading show={showLoading} />
      <h1 className="font-weight-bold mb-5">
        {formatMessage({ id: "step-title" })}
      </h1>
      <p>
        {formatMessage({ id: "step-2" })}
      </p>
      <div className="d-flex">
        {
          Object.keys(InvoiceChannelItems).map((itemKey) => {
            // @ts-ignore
            const item = InvoiceChannelItems[itemKey]
            return (
              <InvoiceItem
                title={formatMessage({ id: item.title }) as string}
                iconSrc={item.iconSrc}
                selected={selectedChannel === itemKey}
                onClick={() => setSelectedChannel(itemKey)}
              />
            )
          })
        }
      </div>
      <div className="mt-5">
        <button className="btn btn-enbw" disabled={!selectedChannel || showLoading} onClick={handleSubmit}>
          {formatMessage({ id: "continue" })}
        </button>
      </div>
    </div>
  )
}

export default InvoiceChannel