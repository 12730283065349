import React, { useState } from 'react'
import { Col, Image } from 'react-bootstrap'


interface IInvoiceChannelProps {
  title: string;
  iconSrc: string;
  description: string;
}


const InvoiceChannel = ({ title, iconSrc, description }: IInvoiceChannelProps) => {
  const [isDescriptionShown, setIsDescriptionShown] = useState(false)

  const toggleDesciptionShown = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    setIsDescriptionShown(prevState => !prevState)
  }

  return (
    <Col md={6} className="p-3 text-center">
      <Col className="invoice-channel-item">
        <p className="font-weight-bold">{title}</p>
        <div className="text-center">
          <Image src={iconSrc} className="invoice-img" />
        </div>
        {isDescriptionShown && <p dangerouslySetInnerHTML={{ __html: description }} className="mt-3 text-left" />}
        <button className="btn btn-enbw-round invoice-btn-text mt-3" onClick={toggleDesciptionShown}>
          {isDescriptionShown ? '-' : '+'}
        </button>
      </Col>
    </Col>
  )
}

export default InvoiceChannel