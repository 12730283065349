import React, { useEffect, useRef, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import debounce from "lodash/debounce";
import bannerImg from "../assets/ballpen-blur-close-up-computer-461077_cropped.jpg";
import bannerImgEnKK from "../assets/nuclear.jpg";
import ProcessItem from "../components/ProcessItem";
import Step1 from "../assets/icons/step1.svg";
import Step2 from "../assets/icons/step2.svg";
import Step3 from "../assets/icons/step3.svg";
import InvoicePaper from "../assets/icons/invoice_paper.svg";
import InvoiceElectronic from "../assets/icons/invoice_electronic.svg";
import InvoiceZugfred from "../assets/icons/invoice_zugferd.svg";
import InvoiceX from "../assets/icons/invoice_x.svg";
import BG2 from "../assets/laptop_1580725251968.jpg";
import { useIntl, } from 'react-intl'
import InvoiceChannel from "../components/InvoiceChannel";
import InterestYouItem from "../components/InterestYouItem";
import Faq from "../components/Faq";
import AuthorizeModal from "../components/AuthorizeModal";
import Assets from "../components/Assets";
import { LocationEnum } from "../types/assets";


interface IHomeProps {
  activeLanguage: "de" | "en";
}


const Home = ({ activeLanguage }: IHomeProps) => {
  const { formatMessage } = useIntl();
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(false);
  const downloadRef = useRef<HTMLElement>(null);
  const faqRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleHashChange = (event?: HashChangeEvent) => {
      if (window.location.hash === "#downloads") {
        // @ts-ignore
        downloadRef &&
          downloadRef.current &&
          downloadRef.current.scrollIntoView({ block: "start" });
      } else if (window.location.hash === "#faq") {
        // @ts-ignore
        faqRef &&
          faqRef.current &&
          faqRef.current.scrollIntoView({ block: "start" });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };
    window.addEventListener("hashchange", handleHashChange, false);
    handleHashChange();
    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  useEffect(() => {
    let prevHash = "";
    const handleOnScroll = debounce((event: Event) => {
      const currentHash = window.location.hash;
      if (currentHash !== "") {
        if (currentHash !== prevHash) {
          prevHash = currentHash;
        } else {
          window.history.replaceState({}, "", "#");
        }
      } else {
        prevHash = "";
      }
    }, 300);
    window.addEventListener("scroll", handleOnScroll, false);
    return () => {
      window.removeEventListener("scroll", handleOnScroll, false);
    };
  }, []);

  const isEnkk = () => {
    return process.env.REACT_APP_TENANT === "enkk";
  };

  const contactMailAddress = isEnkk()
    ? "kreditoren-enkk@enbw.com"
    : "lieferantenvereinbarung@enbw.com";

  // const otherSiteLink = isEnkk()
  //   ? "https://rechnungsbearbeitung.enbw.com/"
  //   : "https://rechnungsbearbeitung.enbw-kk.de/";

  return (
    <div>
      <section className="banner-container">
        <Col xl={6} className="banner-left">
          <Col xl={12} className="banner-inner-container">
            <div className="text-container">
              <p className="banner-title-line1">
                {formatMessage({ id: "banner-title-welcome" })}
              </p>
              <p className="banner-title-line2">
                {formatMessage({ id: "banner-title-line2" })}
              </p>
              <p className="banner-sub-title">
                {formatMessage({ id: "banner-subtitle" })}
              </p>
            </div>
          </Col>
        </Col>
        <Col
          xl={6}
          className={`banner-right ${isEnkk() ? "banner-right--enkk" : ""}`}
        >
          <Col xl={12} className="banner-inner-container">
            <Image
              src={isEnkk() ? bannerImgEnKK : bannerImg}
              className="banner-img"
            />
            <div className="text-container">
              <p className="banner-title-line1">
                {formatMessage({ id: "banner-title-full" })}
              </p>
            </div>
          </Col>
        </Col>
      </section>
      <section
        id="#portal-description"
        className="portal-description-container"
      >
        <p className="portal-description-text">
          {formatMessage({ id: "portal-description" })}
        </p>
      </section>
      <section id="#process" className="process-container">
        <ProcessItem
          itemNo={1}
          title={formatMessage({ id: ("process-step-1") }) as string}
          subtitle={formatMessage({ id: ("process-step-1-desc") }) as string}
          iconSrc={Step1}
          btnTitle="registrieren"
          handleAuthorizeModal={setShowAuthorizeModal}
        />
        <ProcessItem
          itemNo={2}
          title={formatMessage({ id: ("process-step-2") }) as string}
          subtitle={formatMessage({ id: ("process-step-2-desc") }) as string}
          iconSrc={Step2}
          handleAuthorizeModal={setShowAuthorizeModal}
        />
        <ProcessItem
          itemNo={3}
          title={formatMessage({ id: ("process-step-3") }) as string}
          subtitle={formatMessage({ id: ("process-step-3-desc") }) as string}
          iconSrc={Step3}
          btnTitle="login"
          handleAuthorizeModal={setShowAuthorizeModal}
        />
      </section>
      <section id="#channels" className="invoice-channel-container">
        <p className="section-title">
          {formatMessage({ id: "section-invoice-channel" })}
        </p>
        <Row>
          <InvoiceChannel
            title={formatMessage({ id: ("paper-invoice") }) as string}
            iconSrc={InvoicePaper}
            description={formatMessage({ id: ("paper-invoice-desc") }) as string}
          />
          <InvoiceChannel
            title={formatMessage({ id: ("e-invoice") }) as string}
            iconSrc={InvoiceElectronic}
            description={formatMessage({ id: ("e-invoice-desc") }) as string}
          />
          <InvoiceChannel
            title={formatMessage({ id: ("zugferd-invoice") }) as string}
            iconSrc={InvoiceZugfred}
            description={formatMessage({ id: ("zugferd-invoice-desc") }) as string}
          />
          <InvoiceChannel
            title={formatMessage({ id: ("x-invoice") }) as string}
            iconSrc={InvoiceX}
            description={formatMessage({ id: ("x-invoice-desc") }) as string}
          />
        </Row>
      </section>
      <section id="#faq" ref={faqRef} className="faq-container">
        <p className="section-title">
          {formatMessage({ id: "section-faq" })}
        </p>
        <Faq activeLanguage={activeLanguage} />
      </section>
      <section id="#interests" className="interest-you-container">
        <p className="section-title">
          {formatMessage({ id: "section-interest-you" })}
        </p>
        <Row>
          {isEnkk() ? (
            <>
              <InterestYouItem
                title={formatMessage({ id: ("enbw-portal") }) as string}
                subtitle={formatMessage({ id: ("enbw-portal-desc") }) as string}
                bgSrc={bannerImg}
                href="https://rechnungsbearbeitung.enbw.com/"
              />
              <InterestYouItem
                title={formatMessage({ id: ("nuclear-portal") }) as string}
                subtitle={formatMessage({ id: ("nuclear-portal-desc") }) as string}
                bgSrc={bannerImgEnKK}
                href="https://www.enbw.com/unternehmen/konzern/energieerzeugung/kernenergie/"
              />
            </>
          ) : (
            <>
              <InterestYouItem
                title={formatMessage({ id: ("supplier-portal") }) as string}
                subtitle={formatMessage({ id: ("supplier-portal-desc") }) as string}
                bgSrc={BG2}
                href={formatMessage({ id: ("supplier-portal-url") })}
              />
              <InterestYouItem
                title={formatMessage({ id: ("banner-right-link-button") }) as string}
                subtitle={formatMessage({ id: ("nuclear-portal-desc") }) as string}
                bgSrc={bannerImgEnKK}
                href="https://rechnungsbearbeitung.enbw-kk.de/"
              />
            </>
          )}
        </Row>
      </section>
      <section id="#downloads" ref={downloadRef} className="faq-container">
        <p className="section-title">
          {formatMessage({ id: "section-download" })}
        </p>
        <Assets activeLanguage={activeLanguage} location={LocationEnum.Home} />
      </section>
      <section id="#contact" className="contact-container text-center">
        <p className="contact-title">
          {formatMessage({ id: "contact-title" })}
        </p>
        <p>
          {formatMessage({ id: "contact-desc" })}
          <a href={`mailto:${contactMailAddress}`} className="link-enbw">
            {contactMailAddress}
          </a>
        </p>
      </section>

      <AuthorizeModal
        show={showAuthorizeModal}
        onHide={() => setShowAuthorizeModal(false)}
        activeLanguage={activeLanguage}
      />
    </div>
  );
};

export default Home;
