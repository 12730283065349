import React from "react";
import { Row } from "react-bootstrap";
import FaqItem from "./FaqItem";
import { useAssets } from "../hooks/assets";
import { LocationEnum } from "../types/assets";

export interface IFaq {
  question: string;
  answer: string;
}

const contactMailAddress =
  process.env.REACT_APP_TENANT === "enkk"
    ? "kreditoren-enkk@enbw.com"
    : "lieferantenvereinbarung@enbw.com";
const questionsListDE = (how_to_register_url?: string, how_to_add_new_email_addresses?: string): IFaq[] => [
  {
    question: "Wie kann ich mich registrieren?",
    answer:
      "<a href='" +
      how_to_register_url +
      "' target='_blank'>Hier</a> erklären wir Ihnen in wenigen Schritten, wie Sie sich registrieren können.",
  },
  {
    question: "Wie kann ich weitere Mailadressen hinzufügen?",
    answer:
      "Wir zeigen Ihnen <a href='" +
      how_to_add_new_email_addresses +
      "' target='_blank'>hier</a>, wie Sie neue Mailadressen hinzufügen können, wenn Sie bereits bei uns registriert sind.",
  },
  {
    question: "Welche Möglichkeiten der Rechnungsstellung gibt es?",
    answer:
      "Sie können Ihre Rechnung in Papierform, Elektronisch (PDF Datei), im ZUGFeRD Format (ab 2.0), oder als XRechnung stellen.",
  },
  {
    question:
      "Wie muss eine ZUGFeRD Rechnung dargestellt werden und wie ist deren Umfang?",
    answer:
      "Informationen zu ZUGFeRD finden Sie unter <a href='https://www.ferd-net.de/standards/zugferd-faq/index.html' target='_blank' class='nav-link-enbw'>https://www.ferd-net.de/standards/zugferd-faq/index.html</a>",
  },
  {
    question:
      "Wie muss eine XRechnung dargestellt werden und wie ist deren Umfang?",
    answer:
      "Informationen zur XRechnung finden Sie unter <a href='https://www.e-rechnung-bund.de/faq/xrechnung/' target='_blank' class='nav-link-enbw'>https://www.e-rechnung-bund.de/faq/xrechnung/</a>",
  },
  {
    question: "Was sind die gesetzlichen Pflichtbestandteile einer Rechnung?",
    answer: "Dies ist im §14 Abs. 1 und 4 UStG festgelegt.",
  },
  {
    question:
      "Was geschieht, wenn die Qualitätsanforderung an die Rechnung nicht eingehalten wird?",
    answer:
      "Sollte die Rechnung den Standards nicht genügen, wird Ihre Rechnung abgelehnt und Sie werden kontaktiert.",
  },
  {
    question: "Gibt es noch Rechnungen, die zwingend die Papierform erfordern?",
    answer:
      "Nein, elektronische Rechnungen sind Rechnungen in Papierform gleichgestellt. Dies ist im §14 Abs. 1 und 4 UstG festgelegt.",
  },
  {
    question: "Sind die gesetzlichen Bestandteile einer Rechnung ausreichend?",
    answer:
      "Nein, um Ihre Rechnung zuordnen zu können, benötigen wir weitere Informationen wie z.B. Bestellnummer aus dem Konzern / Kostenstelle und den Namen des Bestellers.",
  },
  {
    question: "An wen kann ich mich bei Fragen oder Problemen wenden?",
    answer: `Mailadresse <a href='mailto:${contactMailAddress}' class='nav-link-enbw'>${contactMailAddress}</a>`,
  },
  {
    question: "Gibt es spezielle Anforderungen für Rechnungen?",
    answer:
      "Anforderungen zur elektronischen Rechnungsstellung können Sie der <a href='#downloads' class='nav-link-enbw'>Lieferantenvereinbarung</a> entnehmen.",
  },
  {
    question: "Gibt es nach Rechnungseingang eine Rückmeldung?",
    answer: "Eine automatische Rückmeldung erfolgt bei Rechnungseingang nicht.",
  },
  {
    question:
      "Der Link ist nicht mehr gültig, wie kann ich mich nun einloggen?",
    answer:
      "Durch erneute Eingabe Ihrer Mailadresse erhalten Sie auch einen neuen Link. Diesen können Sie bei Bedarf immer neu anfordern.",
  },
];

const questionsListEN = (how_to_register_url?: string, how_to_add_new_email_addresses?: string): IFaq[] => [
  {
    question: "How can I register?",
    answer:
      "You can find a quick step-by-step guide on how to register <a href='" +
      how_to_register_url +
      "' target='_blank'>here</a>.",
  },
  {
    question: "How can I add new e-mail addresses?",
    answer:
      "Click <a href='" +
      how_to_add_new_email_addresses +
      "' target='_blank'>here</a> to get a quick overview on how to add new e-mail addresses to the electronic invoicing.",
  },
  {
    question: "Which possibilities do I have for invoicing?",
    answer: "Paper-based, e-invoice (pdf), ZUGFeRD or XRechnung.",
  },
  {
    question: "How can I use the ZUGFeRD format?",
    answer:
      "You can find information about ZUGFeRD on the following Website: <a href='https://www.ferd-net.de/standards/zugferd-faq/index.html' target='_blank' class='nav-link-enbw'>https://www.ferd-net.de/standards/zugferd-faq/index.html</a>",
  },
  {
    question: "How can I use the XRechnung format?",
    answer:
      "You can find information about XRechnung on the following Website: <a href='https://www.e-rechnung-bund.de/faq/xrechnung/' target='_blank' class='nav-link-enbw'>https://www.e-rechnung-bund.de/faq/xrechnung/</a>",
  },
  {
    question: "What are legal requirements of an invoice?",
    answer: "This is defined by law in §14 (1) and (4) UstG.",
  },
  {
    question:
      "What is happening when my invoice doesn't meet the requirements?",
    answer:
      "If your invoice is not meeting the requirements, we will contact you.",
  },
  {
    question: "Are there any invoices that need to be paper-based?",
    answer:
      "No, electronic invoices are seen equivalent to paper-based invoices. This is by law §14 (1) and (4) UstG.",
  },
  {
    question: "Is it enough if my invoice is meeting the law provisions?",
    answer:
      "No, to assign your invoice we need more information. For example our order number / cost centre and the name of the ordering person.",
  },
  {
    question: "Is there anyone I can contact?",
    answer: `Yes, of course. You can contact us under the following mail address: <a href='mailto:${contactMailAddress}' class='nav-link-enbw'>${contactMailAddress}</a>`,
  },
  {
    question: "Are there special requirements to meet for invoices?",
    answer:
      "To see the requirements you can check the following link to our supplier agreement: <a href='#downloads' class='nav-link-enbw'>Supplier agreement</a>",
  },
  {
    question: "Will I get a notification after sending the invoice?",
    answer: "There is no automatic response when we receive the invoice.",
  },
  {
    question: "The access link is not working anymore, how can I log in now?",
    answer:
      "If you enter your mail address again, you will receive a new magic link. You can request it any time you need.",
  },
];

interface IFaqComp {
  activeLanguage: "en" | "de";
}

const Faq = (props: IFaqComp) => {
  const { activeLanguage } = props;
  const assets = useAssets(LocationEnum.Home);


  const getQuestionsList = () => {
    if (!assets) {
      return [];
    }

    const how_to_register = assets.find((asset) => asset.text_en === "How to register");
    const how_to_add_new_email_addresses = assets?.find((asset) => asset.text_en === "How to add new e-mail addresses");

    if (activeLanguage === "en") {
      return questionsListEN(how_to_register?.file_url_en, how_to_add_new_email_addresses?.file_url_en);
    }
    return questionsListDE(how_to_register?.file_url_de, how_to_add_new_email_addresses?.file_url_de);
  };

  return (
    <Row>
      {getQuestionsList().map((faq, index) => {
        return <FaqItem key={index} faq={faq} />;
      })}
    </Row>
  );
};

export default Faq;
